import React, {useState} from 'react';
import './App.css';
import {useFirebase, UserData} from "./hooks/useFirebase";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function App() {

    const {writeDocument} = useFirebase();
    const {t, i18n} = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    }

    const [form, setForm] = React.useState<UserData>({
        name: "",
        email: "",
        phone: ""
    });

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        writeDocument(form);
        setForm({
            name: "",
            email: "",
            phone: ""
        });
        setModalVisible(true);
    }


    const title = t('metaTitle');
    const description = t('metaDescription');
    const keywords = t('metaKeywords');

    return (
        <>
            <Helmet>
                <html lang={i18n.language}/>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name="keywords" content={keywords}/>
            </Helmet>

            <div className="m-0 p-0 w-full min-h-screen bg-[#222222] !text-white">
                <div className="flex flex-col h-full w-full px-6 md:px-24 ">

                    <header className="h-fit mb-16">
                        <div className="flex flex-col md:flex-row text-center justify-center items-center">
                            <img src="welcome_card.svg" alt="Welcome Card Logo" width={150}/>&nbsp;
                            <h1 className=" text-center justify-center leading-tight text-3xl mb-8 md:pt-16 title text-purple-700">
                                Welcome Card Bariloche
                            </h1>
                        </div>
                        <p className="text-center">{t('description')}</p>
                    </header>

                    <section className="flex flex-wrap w-full gap-x-40 gap-y-20 flex-1 items-center">

                        <div className="w-full lg:flex-1 flex justify-center">
                            <div className="max-w-[500px]">
                                <img src="phones.png" className="hover:scale-105 duration-150"
                                     alt="Welcome Card mobile app"/>
                            </div>
                        </div>

                        <div className="w-full lg:flex-1 flex justify-center">

                            <div className="w-[500px]">
                                <form onSubmit={handleSubmit}
                                      className="flex flex-col w-full justify-center items-center gap-y-6">
                                    <div className="w-full flex flex-col gap-y-2">
                                        <label className="text-left ">{t("name")}</label>
                                        <input type="text" name="name" value={form.name} onChange={handleChange}
                                               className="rounded-input" required/>
                                    </div>
                                    <div className="w-full flex flex-col gap-y-2">
                                        <label className="text-left ">{t("email")}</label>
                                        <input type="email" name="email" value={form.email} onChange={handleChange}
                                               className="rounded-input" required/>
                                    </div>
                                    <div className="w-full flex flex-col gap-y-2">
                                        <label className="text-left ">{t("phone")}</label>
                                        <input type="tel" name="phone" value={form.phone} onChange={handleChange}
                                               className="rounded-input"/>
                                    </div>
                                    <button
                                        className="bg-purple-500 text-white rounded-3xl px-4 py-2 mt-4 w-full hover:scale-105 duration-150">
                                        {t("signIn")}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </section>

                    <footer className="flex gap-x-16 h-fit justify-center mt-12 mb-8">
                        <i title="iOS"
                           className="text-slate-400 fa-brands fa-apple fa-2x  hover:scale-125 duration-150"></i>
                        <i title="Android"
                           className="text-slate-400 fa-brands fa-android fa-2x  hover:scale-125 duration-150"></i>
                        <i title="Navegadores"
                           className="text-slate-400 fa-solid fa-desktop fa-2x  hover:scale-125 duration-150"></i>
                    </footer>
                </div>


                {modalVisible && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen py-4 px-4 pt-40">
                            <div
                                className="relative bg-white rounded-lg text-center w-fit px-20 overflow-hidden shadow-xl transform transition-all">
                                <div className="px-4 py-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        {t("thankYouHeader")}
                                    </h3>
                                    <p className="mt-2 text-sm text-gray-500">
                                        {t("thankYouMessage")}
                                    </p>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-center">
                                    <button
                                        type="button"
                                        className="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700 transition duration-150 ease-in-out"
                                        onClick={closeModal}>
                                        {t("thankYouClose")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default App;
