// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {addDoc, collection, getFirestore} from "firebase/firestore";
import "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC_USs_3mwQezuazhf8P5OT8AePGY4V_BQ",
    authDomain: "welcome-card-wait-list.firebaseapp.com",
    projectId: "welcome-card-wait-list",
    storageBucket: "welcome-card-wait-list.appspot.com",
    messagingSenderId: "1065395799217",
    appId: "1:1065395799217:web:c68365e493e0ae3d968e90",
    measurementId: "G-QXR5G099DS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export const useFirebase = () => {

    const writeDocument = (userData: UserData) => {
        logEvent(analytics, 'purchase');
        const waitersColection = collection(db, 'waiters')
        addDoc(waitersColection, userData)
    }

    return {
        writeDocument,
    }

}

export interface UserData {
    name: string;
    email: string;
    phone?: string;
}
