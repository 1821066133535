import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initReactI18next} from "react-i18next";
import i18n from "i18next";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const resources = {
    en: {
        translation: {
            metaTitle: "Welcome Card Bariloche",
            metaDescription: "Bariloche WelcomeCard is a tourist card that allows you to discover the city with simplicity and comfort. It is full of offers for visitors.",
            metaKeywords: "tourist card, Bariloche, discover city, simplicity, comfort, visitors, offers",
            description: "Coming soon, we will launch our new app so you can access exclusive and never-before-seen benefits in Bariloche. Do you want to be part of this revolution? Subscribe now and be the first to discover everything we have prepared for you.",
            name: "Name *",
            email: "Email *",
            phone: "Phone number (Optional)",
            signIn: "Join waitlist",
            thankYouHeader: "Thank you for joining our waitlist!",
            thankYouMessage: "We will notify you by email as soon as we launch our app.",
            thankYouClose: "Close",
        }
    },
    es: {
        translation: {
            metaTitle: "Welcome Card Bariloche",
            metaDescription: "Bariloche WelcomeCard es una tarjeta turística que te permite descubrir la ciudad con sencillez y comodidad. Está llena de ofertas para visitantes.",
            metaKeywords: "tarjeta turística, Bariloche, descubrir ciudad, sencillez, comodidad, visitantes, ofertas",
            description: "Pronto lanzaremos nuestra nueva app para que puedas acceder a beneficios exclusivos y nunca antes vistos en Bariloche. ¿Quieres ser parte de esta revolución? Suscríbete ahora y sé el primero en descubrir todo lo que tenemos preparado para vos.",
            name: "Nombre *",
            email: "Correo *",
            phone: "Teléfono (Opcional)",
            signIn: "Anotarme en la lista de espera",
            thankYouHeader: "¡Gracias por unirte a nuestra lista de espera!",
            thankYouMessage: "Te notificaremos por email en cuanto lancemos nuestra app.",
            thankYouClose: "Cerrar",
        }
    },
};

// detect the browser language
// @ts-ignore
const browserLang = navigator.language || navigator['userLanguage'];
let lang = 'en'; // your fallback language
if (browserLang.startsWith('es')) { //covering for all the dialects of Spanish
    lang = 'es';
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lang,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;


root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
